function Loading() {
    return (<div className="loading-overlay">
        <section className='menu-two'>
            <div className="auto-container">
                <div className="title-box centered">
                    <div className="subtitle">
                        <span>please wait</span>
                    </div>
                    <div className="pattern-image">
                        <img src={require("../../assets/images/icons/separator.svg").default} alt="mySvgImage" />
                    </div>
                    <h2>
                        Loading
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                    </h2>
                </div>
            </div>
        </section>
    </div>)
}

export default Loading