import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';

import { BASE_API_URL } from '../../utils/Constants';


SwiperCore.use([Navigation]);


function SpecialMenu() {
  const { data = [], isLoading, isError } = useQuery({
    queryKey: ['promoted'],
    queryFn: async () => {
      const response = await axios.get(BASE_API_URL + 'menuItems/promoted');
      return response.data;
    },
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error loading menu data.</div>;
  }

  const options = {
    slidesPerView: 4,
    spaceBetween: 45,
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    autoplay: {
      delay: 7000,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 15,
      },
      600: {
        slidesPerView: 1,
        spaceBetween: 15,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 4,
      },
    },
  };


  return (
    <section className="special-offer">
      <div className="outer-container">
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle">
              <span>what's hot</span>
            </div>
            <div className="pattern-image">
              <img src={require('../../assets/images/icons/separator.svg').default} alt="mySvgImage" />
            </div>
            <h2>Customer Favorites</h2>

          </div>
          <Swiper className="dish-gallery-slider" {...options}
            loop={true}
            spaceBetween={45}
            slidesPerView={4}
            navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
          >
            {data.map((item, index) =>
              <SwiperSlide key={index} className={`offer-block-two ${index % 2 === 0 && ' margin-top'}`}>
                <div className="inner-box">
                  <div className="image">
                    <Link to="/">
                      <img src={BASE_API_URL + item.promoted_url} alt="" />
                    </Link>
                  </div>
                  <h4>
                    <Link to="#">{item.name}</Link>
                  </h4>
                  <div className="text desc">{item.description}</div>
                  <div className="price">{'HK$' + item.price}</div>
                </div>
              </SwiperSlide>
            )}
          </Swiper>
          <div className="swiper-button-prev">
            <span className="fal fa-angle-left"></span>
          </div>
          <div className="swiper-button-next">
            <span className="fal fa-angle-right"></span>
          </div>
          <div className="lower-link-box text-center">
            <Link to="/menu" className="theme-btn btn-style-two clearfix">
              <span className="btn-wrap">
                <span className="text-one">view all menu</span>
                <span className="text-two">view all menu</span>
              </span>
            </Link>
          </div>
        </div>
      </div>
    </section >
  );
}

export default SpecialMenu;
