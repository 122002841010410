import { useContext, useState } from "react";
import axios from 'axios';
import { OrderContext } from "../../../contexts/OrderContext";
import { BASE_API_URL } from "../../../utils/Constants";

function VerifyPhone() {
    const { userData, setUserData, setAddresses } = useContext(OrderContext);
    const [phoneNumber, setPhoneNumber] = useState(userData?.phone || '');
    const [codeString, setCodeString] = useState(userData?.code || '');
    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const [isCodeValid, setIsCodeValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const validatePhoneNumber = (number) => /^\+852\d{8}$/.test(number);

    const handlePhoneChange = (e) => {
        let value = e.target.value;
        if (value.startsWith('+852')) value = value.slice(4);

        const fullPhoneNumber = value !== '' ? `+852${value}` : '';
        setPhoneNumber(fullPhoneNumber);
        setIsPhoneValid(validatePhoneNumber(fullPhoneNumber));
    };

    const handleCodeChange = (e) => {
        const value = e.target.value;
        setCodeString(value);
        setIsCodeValid(/^\d{4}$/.test(value));
    };

    const handleGetCode = async () => {
        if (!isPhoneValid) return;
        setIsLoading(true);
        setError(null);

        try {
            const response = await axios.post(`${BASE_API_URL}users/getCode`, { phone: phoneNumber });
            setUserData((prev) => ({
                ...prev,
                phone: response.data.phone
            }));
        } catch (err) {
            console.error('Error sending phone number:', err);
            setError('Failed to send the code. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleVerify = async () => {
        if (!isCodeValid) return;
        setIsLoading(true);
        setError(null);

        try {
            const response = await axios.post(`${BASE_API_URL}users/verifyCode`, {
                phone: phoneNumber,
                code: codeString
            });
            setUserData((prev) => ({
                ...prev,
                code: codeString,
                first_name: response.data.first_name,
	            last_name: response.data.last_name,
            }));
            setAddresses(response.data.addresses);
        } catch (err) {
            console.error('Error verifying code:', err);
            setError('Failed to verify the code. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const resetPhone = () => {
        setUserData((prev) => ({
            ...prev,
            phone: '',
            code: ''
        }));
        setPhoneNumber('');
        setCodeString('');
        setIsCodeValid(false);
    };

    return (
        <div className="detail-row">
            <div className="detail-step">
                <span><i className="icon far fa-phone" /> Verify your number</span>
                {userData?.phone && userData?.code && <span className="step-icon far fa-check" />}
            </div>
            
            <div className='detail-body'>
                <div className='form-group'>
                    {/* Phone Number Input */}
                    {!userData?.phone ? (
                        <div className="full">
                            <span className="alt-icon far fa-phone"></span>
                            <input
                                type="tel"
                                value={phoneNumber}
                                onChange={handlePhoneChange}
                                disabled={isLoading}
                                placeholder="+852 xxxxxxxx"
                                required
                            />
                            {isPhoneValid ? (
                                <button
                                    onClick={handleGetCode}
                                    className="theme-btn btn-style-one clearfix"
                                    disabled={isLoading}
                                >
                                    <span className="btn-wrap">
                                        <span className="text-one">{isLoading ? 'Sending...' : 'Get Code'}</span>
                                        <span className="text-two">{isLoading ? 'Sending...' : 'Get Code'}</span>
                                    </span>
                                </button>
                            ) : (
                                <button className="disabled" disabled>Enter Valid Phone</button>
                            )}
                            {error && <p className="error-message">{error}</p>}
                        </div>
                    ) : (
                        <div className="full">
                            <span className="alt-icon far fa-phone"></span>
                            <input className="disabled" type="tel" value={userData.phone} disabled />
                        </div>
                    )}

                    {/* Code Input */}
                    {userData?.phone && !userData?.code && (
                        <>
                            <div className="full">
                                <span className="alt-icon far fa-key"></span>
                                <input
                                    type="number"
                                    value={codeString}
                                    onChange={handleCodeChange}
                                    disabled={isLoading}
                                    placeholder="SMS code"
                                    required
                                />
                                {isCodeValid ? (
                                    <button
                                        onClick={handleVerify}
                                        className="theme-btn btn-style-one clearfix"
                                        disabled={isLoading}
                                    >
                                        <span className="btn-wrap">
                                            <span className="text-one">{isLoading ? 'Sending...' : 'Verify'}</span>
                                            <span className="text-two">{isLoading ? 'Sending...' : 'Verify'}</span>
                                        </span>
                                    </button>
                                ) : (
                                    <button className="disabled" disabled>Enter Valid Code</button>
                                )}
                                {error && <p className="error-message">{error}</p>}
                            </div>
                            <span onClick={resetPhone} className="reset-link">Reset</span>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default VerifyPhone;
