import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Footer from '../components/Footer/Main';
import Header from '../components/Header/Main';
import Home from '../components/Home/Main';
import Menu from '../components/Menu/Main';
import Order from '../components/Order/Main';
import { OrderProvider } from '../contexts/OrderContext';
import { Snackbar, Alert } from '@mui/material';

function Index() {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarKey, setSnackbarKey] = useState(0);

  const triggerSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarKey(prevKey => prevKey + 1);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <Header />
      <OrderProvider triggerSnackbar={triggerSnackbar}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/order" element={<Order />} />
        </Routes>
        <Snackbar
          key={snackbarKey}
          open={openSnackbar}
          autoHideDuration={2000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity="success">
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </OrderProvider>
      <Footer triggerSnackbar={triggerSnackbar}/>
    </>
  )
}

export default Index