import { useContext } from 'react';
import { ReactComponent as Trash } from '../../assets/images/icons/trash.svg';
import { OrderContext } from '../../contexts/OrderContext';

function OrderSummary() {
    const {
        orderItems,
        removeOrderItem,
        adjustQuantity,
        isSelfPickup,
        isAddressConfirmed,
        deliveryFee,
        discount,
        subTotal,
        total
    } = useContext(OrderContext);

    return (
        <div className="order-summary">
            <div className="order-items">
                {orderItems.map((item, index) =>
                (<div key={index} className='item-row'>
                    <div className="item-name">{item.name}</div>
                    <div className="item-quantity">
                        <button className='dec-button' onClick={() => adjustQuantity(index, false)} disabled={isAddressConfirmed || item.quantity <= 1} />
                        <span>{item.quantity}</span>
                        <button className="inc-button" onClick={() => adjustQuantity(index, true)} disabled={isAddressConfirmed} />
                    </div>
                    <div className="item-price">HK${item.price * item.quantity}</div>
                    <div>
                        {isAddressConfirmed ?
                            <Trash
                                className="item-action-disabled"
                            /> : <Trash
                                className="item-action"
                                onClick={() => removeOrderItem(index)}
                            />}
                    </div>
                </div>))}
                <hr />
                <div className='info-row'>
                    <div className='info-name'>Sub Total:</div>
                    <div className='info-amount'>HK${subTotal}</div>
                </div>
                {discount > 0 && (
                    <div className='info-row'>
                        <div className='info-name'>Discount</div>
                        <div className='info-amount'>- HK${discount}</div>
                    </div>
                )}
                {!isSelfPickup && (
                    <div className='info-row'>
                        <div className='info-name'>Delivery Fee:</div>
                        <div className='info-amount'>HK${deliveryFee}</div>
                    </div>
                )}
                <div className='info-row'>
                    <div className='info-name'>Total:</div>
                    <div className='info-amount'>HK${total}</div>
                </div>
            </div>
        </div>
    );
}

export default OrderSummary;