import { useContext, useEffect, useRef } from "react";
import { OrderContext } from "../../../contexts/OrderContext";

function Payment() {
    const { userData, isAddressConfirmed, total, placeOrder } = useContext(OrderContext);
    const paypalButtonRef = useRef();
    const paypalInitialized = useRef(false);

    useEffect(() => {
        if (isAddressConfirmed && total > 0 && window.paypal && !paypalInitialized.current) {
            window.paypal.Buttons({
                createOrder: function (data, actions) {
                    return actions.order.create({
                        purchase_units: [{
                            amount: {
                                value: total.toString(),
                            },
                            description: 'Halal Food HK Payment',
                        }]
                    });
                },
                onApprove: function (data, actions) {
                    return actions.order.capture().then(function (details) {
                        placeOrder(details.id);
                    });
                }
            }).render(paypalButtonRef.current);

            paypalInitialized.current = true;
        }
    }, [isAddressConfirmed, total, placeOrder]);

    useEffect(() => {
        return () => {
            paypalInitialized.current = false;
        };
    }, []);

    return (
        <div className="detail-row">
            <div className="detail-step">
                <span><i className="icon far fa-credit-card" /> Payment</span>
                {(!userData.phone || !userData.code || !isAddressConfirmed) && <span className="icon far fa-lock" />}
            </div>
            {isAddressConfirmed && (
                <div className="detail-body">
                    <div ref={paypalButtonRef}></div>
                    <div onClick={() => placeOrder('test')}>Order</div>
                </div>
            )}
        </div>
    );
}

export default Payment;