import React from "react";
import MenuItem from "./MenuItem";

function ListMenu({ menu }) {
  return (
    <>
      <section id={menu.code} className="menu-two">
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle">
              <span>{menu.name_cn}</span>
            </div>
            <div className="pattern-image">
              <img src={require("../../assets/images/icons/separator.svg").default} alt="mySvgImage" />
            </div>
            <h2>{menu.name}</h2>
          </div>
          <div className="row clearfix">
            <div className="menu-col col-lg-6 col-md-12 col-sm-12">
              <div className="inner">
                {menu.menu_items.map((item, index) => (
                  index < menu.menu_items.length / 2 && <MenuItem key={index} item={item} withImage />
                ))}
              </div>
            </div>
            <div className="menu-col col-lg-6 col-md-12 col-sm-12">
              <div className="inner">
                {menu.menu_items.map((item, index) => (
                  index >= menu.menu_items.length / 2 && <MenuItem key={index} item={item} withImage />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ListMenu;
