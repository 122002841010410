import React from 'react'

import badge1 from '../../assets/images/resource/badge-1.png'
import img1 from '../../assets/images/resource/image-1.jpg'
import img2 from '../../assets/images/resource/image-2.jpg'

function OurStory() {
    return (
        <>
            <section className="story-section">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="text-col col-xl-5 col-lg-5 col-md-12 col-sm-12">
                            <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="title-box centered">
                                    <div className="subtitle">
                                        <span> Our story </span>
                                    </div>
                                    <div className="pattern-image">
                                        <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                                    </div>
                                    <h2>How We Started</h2>
                                    <div className="text">Finding authentic halal food in Hong Kong has often been a challenge, leaving many in search of a reliable and convenient solution. Recognizing this need, we created our platform to bring you a diverse range of delicious, halal-certified dishes with ease and confidence.<br/><br/>Our mission is to offer you a seamless dining experience where you can enjoy a variety of mouthwatering meals without the hassle of verifying restaurant certificates. With us, you can savor every bite knowing that each dish meets the highest halal standards, all delivered right to your doorstep.</div>
                                </div>
                            </div>
                        </div>

                        <div className="image-col col-xl-7 col-lg-7 col-md-12 col-sm-12">
                            <div className="inner wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="round-stamp"><img src={badge1} alt="" /></div>
                                <div className="images parallax-scene-1">
                                    <div className="image" data-depth="0.15"><img src={img1} alt="" /></div>
                                    <div className="image" data-depth="0.30"><img src={img2} alt="" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurStory