import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import OrderIcon from '../../assets/images/icons/order.png';
import { OrderContext } from '../../contexts/OrderContext';
import Banner from './Banner';
import MenuSection from './MenuSection';
import Offer from './Offer';
import OurStory from './OurStory';
import SpecialDish from './SpecialDish';
import SpecialMenu from './SpecialMenu';
import WhyUs from './WhyUs';


function Main() {
    const { count } = useContext(OrderContext);
    return (
        <>
            <Banner />
            <Offer />
            <OurStory />
            <SpecialDish />
            <MenuSection />
            <SpecialMenu />
            <WhyUs />
            {count > 0 && (
                <div className="cart-btn">
                    <Link to="/order" className="theme-btn">
                        <span className="icon">
                            <img src={OrderIcon} alt="" title="" />
                        </span>
                        <span className="txt">your order ({count})</span>
                    </Link>
                </div>
            )}
        </>
    )
}

export default Main