import { useContext, useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Img from '../../assets/images/background/order-bg.jpg';
import { OrderContext } from '../../contexts/OrderContext';
import Bredcom from '../Bredcrumb/Main';
import WhyUs from '../Home/WhyUs';
import OrderDetails from './OrderDetails/Main';
import OrderSummary from './OrderSummary';

function Main() {
    const navigate = useNavigate();

    const {
        orderItems
    } = useContext(OrderContext);

    useEffect(() => {
        if (orderItems.length === 0) navigate('/menu');
    }, [navigate, orderItems.length])

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <>
        <Bredcom subtitle="Your Order" title="order summary" Img={Img} />
        <section className="order-section">
            <OrderSummary />
            <OrderDetails />
        </section>
        <WhyUs />
    </>
}

export default Main