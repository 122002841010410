import React, { useContext } from 'react'

import Backgroundoffer from '../../assets/images/background/image-1.jpg';
import recordbg1 from '../../assets/images/background/image-1.jpg'
import badge from '../../assets/images/resource/badge-2.png'
import { OrderContext } from '../../contexts/OrderContext';

function SpecialDish() {

    const { addOrderItem } = useContext(OrderContext);

    const name = "Bhuna Mutton Biryani";
    const price = 118;

    const handleAddItem = (e) => {
        e.preventDefault();
        addOrderItem({
            name,
            price,
            quantity: 1,
            instructions: '',
        });
    }

    return (
        <>
            <section className="special-dish">
                <div className="outer-container">
                    <div className="row clearfix">
                        <div className="image-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="image-layer" style={{ backgroundImage: `url(${Backgroundoffer})` }}></div>
                                <div className="image"><img src={recordbg1} alt="" /></div>
                            </div>
                        </div>
                        <div className="content-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="inner wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">

                                <div className="title-box">
                                    <span className="badge-icon"><img src={badge} alt="" title="" /></span>
                                    <div className="subtitle"><span>Special dish</span></div>

                                    <div className="pattern-image">
                                        <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                                    </div>

                                    <h2>Bhuna Mutton Biryani</h2>
                                    <div className="text">Aromatic basmati rice layered with tender, slow-cooked mutton in a rich, spiced sauce, garnished with fried onions and fresh herbs.</div>
                                </div>

                                <div className="price"><span className="old"><s>HK$138</s></span> <span className="new">HK$118</span></div>
                                <div className="link-box">
                                    <a role="button" onClick={handleAddItem} className="theme-btn btn-style-two clearfix">
                                        <span className="btn-wrap">
                                            <span className="text-one">add to order</span>
                                            <span className="text-two">add to order</span>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SpecialDish