import React from 'react';
import VerifyPhone from './VerifyPhone';
import SetAddress from './SetAddress';
import Payment from './Payment';


function OrderDetails() {

    return (
        <div className="order-details">
            <VerifyPhone />
            <SetAddress />
            <Payment />
        </div>
    );

}

export default OrderDetails;