import React, { useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';

const GoogleAutoComplete = ({ setAddressData }) => {
  const [address, setAddress] = useState('');

  const handleSelect = async (selectedAddress) => {
    setAddress(selectedAddress);

    // Fetch place details using Google Geocoding API
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(selectedAddress)}&key=AIzaSyDorlHcERdLoppRBvGW_ff81fs_i3Xq-IA&loading=async`;

    try {
      const response = await fetch(geocodeUrl);
      const data = await response.json();

      if (data.results && data.results[0]) {
        const result = data.results[0];
        const addressComponents = result.address_components;
        const districtComponent = addressComponents.find(component =>
          component.types.includes('administrative_area_level_1')
        );

        setAddressData((prev) => ({
          ...prev,
          address: result.formatted_address,
          district: districtComponent ? districtComponent.long_name : '',
          lat: result.geometry.location.lat,
          lng: result.geometry.location.lng
        }));
      }
    } catch (error) {
      console.error('Error fetching address details:', error);
    }
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={setAddress}
      onSelect={handleSelect}
      searchOptions={{ componentRestrictions: { country: ['HK'] } }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="full">
          <input
            {...getInputProps({
              placeholder: 'Enter an address',
            })}
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              const className = suggestion.active
                ? 'suggestion-item--active'
                : 'suggestion-item';
              return (
                <div
                  key={suggestion.index}
                  {...getSuggestionItemProps(suggestion, {
                    className,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default GoogleAutoComplete;
