import { useContext, useEffect, useState } from "react";
import axios from 'axios';
import { OrderContext } from "../../../contexts/OrderContext";
import { BASE_API_URL } from "../../../utils/Constants";
import GoogleAutoComplete from './GoogleAutoComplete';

function SetAddress() {
    const {
        userData,
        addresses,
        isAddressConfirmed,
        isSelfPickup,
        setUserData,
        setAddresses,
        setAddressId,
        setDistrict,
        setIsAddressConfirmed,
        setIsSelfPickup
    } = useContext(OrderContext);

    const [firstName, setFirstName] = useState(userData?.first_name || '');
    const [lastName, setLastName] = useState(userData?.last_name || '');
    const [addressData, setAddressData] = useState({ details: '', address: '', district: '', lat: '', lng: '' });
    const [selectedAddressIndex, setSelectedAddressIndex] = useState();
    const [open, setOpen] = useState(false);
    const [isAddressValid, setIsAddressValid] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (addresses.length > 0) {
            const firstAddress = addresses[0];
            setSelectedAddressIndex(0);
            setAddressId(firstAddress.id);
            setDistrict(firstAddress.district);
        }
    }, [addresses, setSelectedAddressIndex, setAddressId, setDistrict]);

    useEffect(() => {
        setIsAddressValid(
            addressData.details !== '' &&
            addressData.address !== '' &&
            addressData.district !== '' &&
            addressData.lat !== '' &&
            addressData.lng !== ''
        );
    }, [addressData]);

    const handleSelect = (e) => {
        const value = e.target.value;
        if (value === 'new') {
            setOpen(true);
            setSelectedAddressIndex('new');
            setAddressData({ details: '', address: '', district: '', lat: '', lng: '' });
            setAddressId(undefined);
            setDistrict(undefined);
            setError(null);
        } else {
            setOpen(false);
            const index = +value;
            setSelectedAddressIndex(index);
            setAddressId(addresses[index].id);
            setDistrict(addresses[index].district);
        }
    };

    const handleDetailChange = (e) => {
        setAddressData((prev) => ({ ...prev, details: e.target.value }));
    };

    const submitAddress = async () => {
        if (!isAddressValid) return;

        setIsSubmitting(true);
        setError(null);

        const hasName = userData.first_name !== '' && userData.last_name !== '';

        const url = hasName ? `${BASE_API_URL}/addresses/add` : `${BASE_API_URL}/users/saveAddressWithName`;
        const additionalData = hasName ? {} : { first_name: firstName, last_name: lastName };

        try {
            const response = await axios.post(url, {
                ...additionalData,
                address: addressData,
                phone: userData?.phone,
                code: userData?.code
            });
            setAddresses(response.data.addresses);
            if (!hasName) setUserData((prev) => ({
                ...prev,
                first_name: response.data.first_name,
                last_name: response.data.last_name
            }));
            const lastAddress = response.data.addresses.at(-1);
            setSelectedAddressIndex(response.data.addresses.length - 1);
            setAddressId(lastAddress.id);
            setDistrict(lastAddress.district);
            setOpen(false);
        } catch (err) {
            setError('Failed to save address. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="detail-row">
            {isSelfPickup ?
                <>
                    <div className="detail-step">
                        <span><i className="icon far fa-shopping-bag" /> Self Pick-Up</span>
                        {isAddressConfirmed && <span className="step-icon far fa-check" />}
                    </div>
                    {!isAddressConfirmed && <div className="detail-body">
                        <div className="form-group">
                            <div className="full">
                                <div className="description">Self Pick-Up form Jordan MTR Station</div>
                            </div>
                            <div className="full">
                                <div onClick={() => setIsAddressConfirmed(true)} className="theme-btn btn-style-two clearfix">
                                    <span className="btn-wrap">
                                        <span className="text-one">Confirm</span>
                                        <span className="text-two">Confirm</span>
                                    </span>
                                </div>
                            </div>
                            <span onClick={() => setIsSelfPickup(false)}>Set Address</span>

                        </div>
                    </div>}
                </> :
                <>
                    <div className="detail-step">
                        <span><i className="icon far fa-home" /> Address</span>
                        {isAddressConfirmed && <span className="step-icon far fa-check" />}
                        {(!userData.phone || !userData.code) && <span className="icon far fa-lock" />}
                    </div>

                    {userData.phone && userData.code && (
                        <div className='detail-body'>
                            <div className='form-group'>
                                <div className="half">
                                    <div>
                                        <input
                                            type="text"
                                            name="first_name"
                                            value={userData.first_name || firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            placeholder="First Name"
                                            required={!userData.first_name}
                                            disabled={!!userData.first_name}
                                        />
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            name="last_name"
                                            value={userData.last_name || lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                            placeholder="Last Name"
                                            required={!userData.last_name}
                                            disabled={!!userData.last_name}
                                        />
                                    </div>
                                </div>

                                {addresses.length > 0 && (
                                    <div className="full">
                                        <select value={selectedAddressIndex} onChange={handleSelect} disabled={isAddressConfirmed}>
                                            {addresses.map((address, index) => (
                                                <option key={index} value={index}>
                                                    {`${address.details}, ${address.address}`}
                                                </option>
                                            ))}
                                            <option value="new">Add new</option>
                                        </select>
                                    </div>
                                )}

                                {(addresses.length === 0 || open) && (
                                    <>
                                        <GoogleAutoComplete setAddressData={setAddressData} />
                                        <div className="full">
                                            <input
                                                type="text"
                                                name="details"
                                                value={addressData.details}
                                                onChange={handleDetailChange}
                                                placeholder="Address details"
                                                required
                                            />
                                            {isAddressValid ? <button
                                                className="theme-btn btn-style-one clearfix"
                                                onClick={submitAddress}
                                                disabled={isSubmitting}
                                            >
                                                <span className="btn-wrap">
                                                    <span className="text-one">{isSubmitting ? 'Saving...' : 'Save'}</span>
                                                    <span className="text-two">{isSubmitting ? 'Saving...' : 'Save'}</span>
                                                </span>
                                            </button> :
                                                <button className="disabled" disabled>Enter Address</button>}
                                            {error && <p className="error-message">{error}</p>}
                                        </div>
                                    </>
                                )}

                                {addresses.length !== 0 && !open && !isAddressConfirmed && <div className="full">
                                    <div onClick={() => setIsAddressConfirmed(true)} className="theme-btn btn-style-two clearfix">
                                        <span className="btn-wrap">
                                            <span className="text-one">Confirm</span>
                                            <span className="text-two">Confirm</span>
                                        </span>
                                    </div>
                                </div>}
                                {!isAddressConfirmed && <span onClick={() => setIsSelfPickup(true)}>Self Pick-Up (-20%)</span>}
                            </div>
                        </div>
                    )}
                </>}
        </div>
    );
}

export default SetAddress;
