import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import './assets/css/style.css';
import './assets/css/responsive.css';
import Routing from './routes';

export default function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60 * 60 * 24,
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routing />
      </Router>
    </QueryClientProvider>
  );
}
