
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Backgroundimage from '../../assets/images/background/image-4.jpg';
import logonew from '../../assets/images/logo.png';
import { BASE_API_URL } from '../../utils/Constants';

function Main({ triggerSnackbar }) {
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleInputChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubscribe = async (e) => {
        e.preventDefault();

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email || !emailRegex.test(email)) {
            triggerSnackbar('Please enter a valid email address.');
            return;
        }

        setIsSubmitting(true);
        try {
            const response = await axios.post(BASE_API_URL + 'subscribers/add', { email });
            triggerSnackbar(response.data.message);
        } catch (err) {
            triggerSnackbar(err.response?.data?.error || 'An error occurred. Please try again later.');
        } finally {
            setEmail('');
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <footer className="main-footer">
                <div className="image-layer" style={{ backgroundImage: `url(${Backgroundimage})` }}></div>
                <div className="upper-section">
                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="footer-col info-col col-lg-6 col-md-12 col-sm-12">
                                <div className="inner wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="content">
                                        <div className="logo"><Link to="/" title="Delici - Restaurants HTML Template"><img src={logonew} alt="" title="Delici - Restaurants HTML Template" /></Link></div>
                                        <div className="info">
                                            <ul>
                                                <li>Pick-up from Jordan MTR Station</li>
                                                <li><Link to="mailto:halalfoodhk786@gmail.com">halalfoodhk786@gmail.com</Link></li>
                                                <li><Link to="tel:+85295201170">Customer Support : +852 9520 1170</Link></li>
                                                <li> Daily : 11:00 - 15:00 and 18:00 - 23:00</li>
                                            </ul>
                                        </div>
                                        <div className="separator"><span></span><span></span><span></span></div>
                                        <div className="newsletter">
                                            <h3>Get News & Offers</h3>
                                            <div className="text">Subscribe to us & Get monthly discounts</div>
                                            <div className="newsletter-form">
                                                <form onSubmit={handleSubscribe} method="post" action="/">
                                                    <div className="form-group">
                                                        <span className="alt-icon far fa-envelope"></span>
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            placeholder="Your email"
                                                            value={email}
                                                            onChange={handleInputChange}
                                                            required
                                                        />
                                                        <button type="submit" className="theme-btn btn-style-one clearfix" disabled={isSubmitting}>
                                                            <span className="btn-wrap">
                                                                <span className="text-one">{isSubmitting ? 'loading...' : 'subscribe'}</span>
                                                                <span className="text-two">{isSubmitting ? 'loading...' : 'subscribe'}</span>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="footer-col links-col col-lg-3 col-md-6 col-sm-12">
                                {/* Additional footer links can be added here */}
                            </div>

                            <div className="footer-col links-col last col-lg-3 col-md-6 col-sm-12">
                                {/* Social links or other info can be added here */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="auto-container">
                        <div className="copyright">&copy; 2025 Halal Food HK | All Rights Reserved</div>
                    </div>
                </div>
            </footer>
            <div className="scroll-to-top scroll-to-target" data-target="html"><span className="icon fa fa-angle-up"></span></div>
        </>
    );
}

export default Main;
