import React from 'react'
import { Link } from 'react-router-dom'
import bg1 from '../../assets/images/background/bg-1.png'
import bg2 from '../../assets/images/background/bg-2.png'
import offerimg1 from '../../assets/images/resource/offer-image-1.jpg'
import offerimg2 from '../../assets/images/resource/offer-image-2.jpg'
import offerimg3 from '../../assets/images/resource/offer-image-3.jpg'

function Offer() {

  const offers = [
    {
      image: offerimg1,
      title: 'Traditional Curry',
      link: '/menu#curry'
    },
    {
      image: offerimg2,
      title: 'Fusion Pizza',
      link: '/menu#pizza'
    },
    {
      image: offerimg3,
      title: 'Flavoursome Rolls',
      link: '/menu#rolls'
    }
  ];

  return (
    <>
      <section className="we-offer-section">
        <div className="right-top-bg"><img src={bg1} alt="" title="" /></div>
        <div className="left-bot-bg"><img src={bg2} alt="" title="" /></div>
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle"><span>Menu Highlights</span></div>
            <div className="pattern-image">
              <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
            </div>
            <h2>Our Must-Try Recommendations</h2>
            <div className="text">Discover our top picks: Traditional Curries bursting with rich, authentic flavors, Flavorful Rolls packed with delightful spices, and Fusion Pizza that combines unique tastes for an unforgettable experience.</div>
          </div>
          <div className="row justify-content-center clearfix">
            {offers.map((offer, index) => (
              <div key={index} className="offer-block col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay={`${index * 300}ms`}>
                  <div className="image"><Link to={offer.link}><img src={offer.image} alt="" /></Link></div>
                  <h3><Link to={offer.link}>{offer.title}</Link></h3>
                  <div className="more-link"><Link to={offer.link}>view menu</Link></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default Offer
