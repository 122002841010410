import React from 'react'
import burger from '../../assets/images/resource/menus-burger.jpg'
import naan from '../../assets/images/resource/menus-naan.jpg'
import MenuItem from './MenuItem'

function BannerMenu({ menu }) {
    return (
        <>
            <section id={menu.code} className={`menu-one ${menu.id % 2 === 0 && 'alternate'}`}>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>{menu.name_cn}</span></div>

                        <div className="pattern-image">
                            <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>

                        <h2>{menu.name}</h2>
                    </div>

                    <div className="row clearfix">
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="image"><img src={menu.code === 'burger' ? burger : naan} alt="" /></div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                            <div className="inner">
                                {menu.menu_items.map((item, index) => <MenuItem key={index} item={item} />)}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BannerMenu