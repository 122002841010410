import React, { createContext, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { BASE_API_URL } from '../utils/Constants';

export const OrderContext = createContext();

export const OrderProvider = ({ children, triggerSnackbar }) => {
    const navigate = useNavigate();

    const [orderItems, setOrderItems] = useState([]); // name, price, quantity, instructions

    const [userData, setUserData] = useState({ phone: '', first_name: '', last_name: '', code: '' });

    const [coupon, setCoupon] = useState();
    const [isCouponSkip, setIsCouponSkip] = useState(false);

    const [addresses, setAddresses] = useState([]);
    const [isSelfPickup, setIsSelfPickup] = useState(false);
    const [isAddressConfirmed, setIsAddressConfirmed] = useState(false);
    const [addressId, setAddressId] = useState();
    const [district, setDistrict] = useState();

    const [deliveryFee, setDeliveryFee] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [total, setTotal] = useState(0);

    const getSubtotal = useCallback(() => {
        return orderItems.reduce((total, item) => total + item.price * item.quantity, 0);
    }, [orderItems]);

    const fetchDeliveryFee = useCallback(async (addressId) => {
        try {
            const response = await axios.get(`${BASE_API_URL}addresses/calculate-delivery-fee/${addressId}`);
            const roundedFee = Math.round(response.data.delivery_fee);
            setDeliveryFee(roundedFee);
        } catch (err) {
            triggerSnackbar(err.response?.data?.error || 'Failed to fetch delivery fee');
            setDeliveryFee(0);
        }
    }, [triggerSnackbar]);

    useEffect(() => {
        if (!isSelfPickup && addressId) {
            fetchDeliveryFee(addressId);
        } else {
            setDeliveryFee(0);
        }
    }, [addressId, isSelfPickup, fetchDeliveryFee]);

    useEffect(() => {
        const subTotal = getSubtotal();
        const discountAmount = isSelfPickup ? Math.round(subTotal * 0.20) : 0; // 20% discount for self-pickup
        setDiscount(discountAmount);

        const totalAmount = subTotal - discountAmount + deliveryFee;
        setTotal(totalAmount);
    }, [isSelfPickup, getSubtotal, deliveryFee]);

    const addOrderItem = (newItem) => {
        setOrderItems((prevItems) => {
            const itemIndex = prevItems.findIndex(item => item.name === newItem.name);

            if (itemIndex !== -1) {
                const updatedItems = [...prevItems];
                updatedItems[itemIndex] = {
                    ...updatedItems[itemIndex],
                    quantity: updatedItems[itemIndex].quantity + newItem.quantity,
                };
                triggerSnackbar(`${newItem.name} quantity increased!`);
                return updatedItems;
            }
            triggerSnackbar(`${newItem.name} added to the order!`);
            return [...prevItems, newItem];
        });
    };

    const adjustQuantity = (index, isIncrease) => {
        setOrderItems((prevItems) => {
            const updatedItems = [...prevItems];
            const newQuantity = updatedItems[index].quantity + (isIncrease ? 1 : -1);

            if (newQuantity < 1) {
                return prevItems;
            }
            
            updatedItems[index] = {
                ...updatedItems[index],
                quantity: newQuantity,
            };
            return updatedItems;
        });
    };

    const removeOrderItem = (index) => {
        setOrderItems((prevItems) => {
            const updatedItems = [...prevItems];
            updatedItems.splice(index, 1);
            return updatedItems;
        });
    }

    const getOrderCount = () => {
        return orderItems.reduce((total, item) => total + item.quantity, 0);
    };

    const count = getOrderCount();
    const subTotal = getSubtotal();

    const placeOrder = async (payment) => {
        triggerSnackbar('Placing order...');
        const order = {
            is_pickup: isSelfPickup,
            address_id: !isSelfPickup ? addressId : null,
            sub_total: subTotal,
            delivery_fee: deliveryFee,
            donations: 0,
            discount,
            total,
            payment,
            order_items: orderItems,
        };
        try {
            const response = await axios.post(`${BASE_API_URL}orders/add`, {
                phone: userData.phone,
                code: userData.code,
                order,
            });
            triggerSnackbar(response.data.success);
            navigate('/');
        } catch (err) {
            triggerSnackbar(err.response?.data?.error || 'An error occurred');
        } finally {
            setOrderItems([]);
            setUserData({ phone: '', first_name: '', last_name: '', code: '' });
            setAddresses([]);
            setIsSelfPickup(false);
            setIsAddressConfirmed(false);
            setAddressId(undefined);
            setDistrict(undefined);
            setDeliveryFee(0);
            setDiscount(0);
            setTotal(0);
        }
    };

    const value = {
        orderItems,
        count,
        subTotal,
        deliveryFee,
        discount,
        total,
        userData,
        coupon,
        isCouponSkip,
        addresses,
        addressId,
        district,
        isAddressConfirmed,
        isSelfPickup,
        addOrderItem,
        adjustQuantity,
        removeOrderItem,
        setUserData,
        setCoupon,
        setIsCouponSkip,
        setAddresses,
        setAddressId,
        setDistrict,
        setIsAddressConfirmed,
        setIsSelfPickup,
        placeOrder
    };

    return (
        <OrderContext.Provider value={value}>
            {children}
        </OrderContext.Provider>
    );
};