import { useContext, useState } from "react";
import { BASE_API_URL } from "../../utils/Constants";
import { OrderContext } from "../../contexts/OrderContext";

function MenuItem({ item, withImage }) {
    const { addOrderItem } = useContext(OrderContext);

    const [price, setPrice] = useState(() => {
        if (item.extra_sections.length > 0) return item.price + item.extra_sections[0].extra_items[0].price;
        else return item.price;
    });
    const [selected, setSelected] = useState(() => {
        if (item.extra_sections.length > 0) return item.extra_sections[0].extra_items[0].name;
        else return '';
    });

    const handleSelect = (extra_item) => {
        const newPrice = extra_item.price === 0 ? item.price : item.price + extra_item.price;
        setPrice(newPrice);
        setSelected(extra_item.name);
    }

    const handleAddItem = () => {
        const orderItem = {
            name: selected === '' ? item.name : selected + ' ' + item.name,
            price: price,
            quantity: 1,
            instructions: '',
        };
        addOrderItem(orderItem);
    }

    return <div key={item.id} className="dish-block">
        <div className="inner-box">
            {withImage && <div className="dish-image">
                <img src={BASE_API_URL + item.image_url} alt="" />
            </div>}
            <div className="title clearfix">
                <div className="ttl clearfix">
                    <h5>
                        {item.name}
                    </h5>
                </div>
                <div className="price">
                    <span>{'HK$' + price}</span>
                </div>
            </div>
            <div className="details">
                <div>
                    <div className="text desc">{item.description}</div>
                    {item.extra_sections.length > 0 && item.extra_sections.map(
                        section => <div key={section.id} className="extra-section">
                            <div className="extra-section-name">{section.name}:</div>
                            {section.extra_items.map(
                                (extra_item, index) => <label key={index} className="extra-items">
                                    <input
                                        className="extra-item-radio"
                                        type="radio"
                                        name={section.name}
                                        checked={selected === extra_item.name}
                                        onChange={() => handleSelect(extra_item)}
                                    />
                                    <div className={`extra-item ${selected === extra_item.name ? 'selected' : ''}`}>
                                        {extra_item.price === 0 ? extra_item.name : extra_item.name + ' +HK$' + extra_item.price}
                                    </div>
                                </label>)}
                        </div>)}
                </div>
                <div>
                    <button className="add-button" onClick={handleAddItem} />
                </div>
            </div>
        </div>
    </div>;
}

export default MenuItem;