import React, { useContext, useEffect, useLayoutEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import Img from '../../assets/images/background/menu-bg.jpg';
import OrderIcon from '../../assets/images/icons/order.png';
import { OrderContext } from '../../contexts/OrderContext';
import { BASE_API_URL } from '../../utils/Constants';
import Bredcom from '../Bredcrumb/Main';
import BannerMenu from './BannerMenu';
import ListMenu from './ListMenu';
import Loading from './Loading';

function Main() {
  const { count } = useContext(OrderContext);
  const location = useLocation();

  const { data, isLoading, isError } = useQuery({
    queryKey: ['fullMenu'],
    queryFn: async () => {
      const response = await axios.get(BASE_API_URL + 'menus/full');
      return response.data;
    }
  });

  useEffect(() => {
    if (data && location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [data, location.hash]);

  useLayoutEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location.hash]);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div>Error loading menu data.</div>;
  }

  return (
    <>
      <Bredcom subtitle="Full Menu" title="DELICIOUS & AMAZING" Img={Img} />
      {data.map((menu, index) =>
        menu.menu_items.length > 4 ? (
          <ListMenu key={index} menu={menu} />
        ) : (
          <BannerMenu key={index} menu={menu} />
        )
      )}
      {count > 0 && (
        <div className="cart-btn">
          <Link to="/order" className="theme-btn">
            <span className="icon">
              <img src={OrderIcon} alt="" title="" />
            </span>
            <span className="txt">your order ({count})</span>
          </Link>
        </div>
      )}
    </>
  );
}

export default Main;